import { useIsSSR } from '@looped/hooks';
import { createSparkAuth, SAUser } from '@sparkauth/core';
import { PartialSparkAuthTheme, SALocalization, SATeam, SAUserTeam } from '@sparkauth/shared';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useReducer, useRef } from 'react';

export let sparkauth = createSparkAuth(
  'spark_pub_dev_lfnxnr25_kITfweXMQ_zkKOYyhcETrUdOxE3vWeIhOIj',
  process.env.NODE_ENV == 'development'
    ? {
        apiHost: 'http://localhost:3020',
        configHost: 'http://localhost:3024'
      }
    : {}
);

export let useMustBeAuthenticated = () => {
  let { authStatus } = useAuth();
  let router = useRouter();

  useEffect(() => {
    if (authStatus == 'unauthenticated') router.push('/auth');
  }, [authStatus]);
};

export let useAuth = () => {
  let [_, rerender] = useReducer(x => x + 1, 0);
  let isServer = useIsSSR();

  useEffect(() => {
    let u1 = sparkauth.onAuthStateChanged(() => rerender());
    let u2 = sparkauth.onUserChanged(() => rerender());
    let u3 = sparkauth.onTeamsChanged(() => rerender());

    return () => {
      u1();
      u2();
      u3();
    };
  }, []);

  return {
    loading: sparkauth.getAuthStatus() == 'loading',
    authStatus: isServer ? 'loading' : sparkauth.getAuthStatus(),
    user: isServer ? undefined : sparkauth.getUser(),
    teams: isServer ? undefined : sparkauth.getTeams(),
    getTeam: sparkauth.getTeam,
    logout: sparkauth.logout,
    authWithPasskey: sparkauth.authWithPasskey,
    authWithEmail: sparkauth.authWithEmail,
    authWithProvider: sparkauth.authWithProvider,
    client: {
      user: sparkauth.user,
      team: sparkauth.team
    }
  };
};

export let Auth = ({
  theme,
  localization,
  onAuthenticated
}: {
  theme?: PartialSparkAuthTheme;
  localization?: SALocalization;
  onAuthenticated?: (user: SAUser) => void;
}) => {
  let ref = useRef<HTMLDivElement>();

  let onAuthenticatedRef = useRef(onAuthenticated);
  onAuthenticatedRef.current = onAuthenticated;

  useEffect(() => {
    if (!ref.current) return;

    sparkauth.ui.authUI(ref.current, {
      theme,
      localization,
      onAuthenticated: u => onAuthenticatedRef.current?.(u)
    });
  }, [ref]);

  return <div ref={ref as React.RefObject<HTMLDivElement>} />;
};

export let CreateTeam = ({
  theme,
  localization,
  onTeamCreated
}: {
  theme?: PartialSparkAuthTheme;
  localization?: SALocalization;
  onTeamCreated?: (team: SAUserTeam) => void;
}) => {
  let ref = useRef<HTMLDivElement>();

  let onTeamCreatedRef = useRef(onTeamCreated);
  onTeamCreatedRef.current = onTeamCreated;

  useEffect(() => {
    if (!ref.current) return;

    sparkauth.ui.createTeamUI(ref.current, {
      theme,
      localization,
      onTeamCreated: t => onTeamCreatedRef.current?.(t)
    });
  }, [ref]);

  return <div ref={ref as React.RefObject<HTMLDivElement>} />;
};

export let User = ({
  theme,
  localization,
  uploader,
  onTeamClick
}: {
  theme?: PartialSparkAuthTheme;
  localization?: SALocalization;
  uploader?: (file: File) => Promise<string>;
  onTeamClick?: (team: SAUserTeam) => void;
}) => {
  let ref = useRef<HTMLDivElement>();

  let onTeamClickRef = useRef(onTeamClick);
  onTeamClickRef.current = onTeamClick;

  useEffect(() => {
    if (!ref.current) return;

    sparkauth.ui.userUI(ref.current, {
      theme,
      localization,
      uploader,
      onTeamClick: t => onTeamClickRef.current?.(t)
    });
  }, [ref]);

  return <div ref={ref as React.RefObject<HTMLDivElement>} />;
};

export let Team = ({
  theme,
  localization,
  uploader,
  teamId,
  onTeamUpdate
}: {
  teamId: string;
  theme?: PartialSparkAuthTheme;
  localization?: SALocalization;
  uploader?: (file: File) => Promise<string>;
  onTeamUpdate?: (team: SATeam) => void;
}) => {
  let ref = useRef<HTMLDivElement>();

  let onTeamUpdateRef = useRef(onTeamUpdate);
  onTeamUpdateRef.current = onTeamUpdate;

  useEffect(() => {
    if (!ref.current) return;

    sparkauth.ui.teamUI(ref.current, {
      teamId,
      theme,
      localization,
      uploader,
      onTeamUpdate: t => onTeamUpdateRef.current?.(t)
    });
  }, [ref, teamId]);

  return <div ref={ref as React.RefObject<HTMLDivElement>} />;
};

export let useTeam = (teamId: string | undefined | null) => {
  let { loading, teams } = useAuth();
  let team = useMemo(
    () => teams?.find(t => t.id == teamId || t.slug == teamId),
    [teams, teamId]
  );

  return {
    loading,
    team,
    notFound: !loading && !team && !!teamId
  };
};
