import { createGlobalState, useDelayed } from '@looped/hooks';
import { Spinner } from '@vapor/ui';
import { styled } from 'buttered';

let state = createGlobalState(false);

export let setGlobalLoading = (v: boolean) => state.setState(v);

let Wrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  transition: opacity 0.3s;
`;

export let GlobalLoading = () => {
  let [loading] = state();
  let render = useDelayed(loading, 500, [true]);
  let delayed = useDelayed(loading, 10);

  if (!render) return null;

  return (
    <Wrapper
      style={{
        opacity: delayed ? 1 : 0,
        pointerEvents: loading ? 'all' : 'none'
      }}
    >
      <Spinner size={30} />
    </Wrapper>
  );
};
