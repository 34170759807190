import { httpBatchLink, loggerLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import superjson from 'superjson';
import type { AppRouter } from '../server/routes/index';
import { sparkauth } from './auth';

let getBaseUrl = () => {
  if (typeof window != 'undefined') return '';

  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }

  return `http://localhost:${process.env.PORT ?? 3000}`;
};

export const trpc = createTRPCNext<AppRouter>({
  config({ ctx }) {
    return {
      transformer: superjson,

      links: [
        loggerLink({
          enabled: opts => true
        }),

        httpBatchLink({
          url: `${getBaseUrl()}/api/private`,

          async headers() {
            return {
              Authorization: `Bearer ${await sparkauth.getToken()}`
            };
          }
        })
      ],

      queryClientConfig: {
        defaultOptions: {
          queries: {
            staleTime: 10 * 1000
          }
        }
      }
    };
  },

  ssr: false
});
