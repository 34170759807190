import { VaporProvider } from '@vapor/ui';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { GlobalLoading } from '../lib/globalLoading';
import { trpc } from '../state/trpc';
import '../styles/global.css';
import '../styles/reset.css';
import '../styles/variables.css';

export default trpc.withTRPC(({ Component, pageProps }: AppProps) => {
  return (
    <VaporProvider ssr>
      <Head>
        <link rel="stylesheet" href="https://fonts.loopbound.com/inter.css" />
        <title>SparkAuth</title>
      </Head>

      <Component {...pageProps} />

      <GlobalLoading />
    </VaporProvider>
  );
});
